import { ParsedUrlQuery } from 'querystring';
import { isArray } from '@pe-libs/react-components/build/lib/helper';

export const getKeyword = (query: ParsedUrlQuery) => {
  if (query.slugs) {
    return isArray(query.slugs) ? query.slugs[0] : query.slugs;
  }

  if (query.keyword) {
    return isArray(query.keyword) ? query.keyword[0] : query.keyword;
  }

  return '';
};

export const getLocation = (query: ParsedUrlQuery) => {
  if (query.slugs) {
    return isArray(query.slugs) ? query.slugs[1] : query.slugs;
  }

  if (query.location) {
    return isArray(query.location) ? query.location[0] : query.location ?? '';
  }

  return '';
};

export const getPage = (query: ParsedUrlQuery) => {
  if (query?.page) {
    return isArray(query.page) ? query.page[0] : query.page;
  }

  return 1;
};

export const getLang = (query: ParsedUrlQuery) => {
  if (query.lang) {
    return isArray(query.lang) ? query.lang[0] : query.lang;
  }

  return 'de-de';
};
